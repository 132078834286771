import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbPasswordAuthStrategy, NbAuthModule, NbAuthJWTToken } from '@nebular/auth';
import { NgxAuthModule } from './auth/auth.module'
import { APP_BASE_HREF } from '@angular/common';
import { AuthGuard } from './auth-guard.service';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToasterModule } from 'angular2-toaster';
import { CommonModule } from "@angular/common";
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbCardModule,
  NbCheckboxModule,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbLayoutModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTooltipModule
} from '@nebular/theme';
import { EnvServiceProvider } from './env.service.provider';
import { InterceptorComponent } from './interceptor/interceptor.component';
import { GoogleAnalyticsServiceService } from './services/google-analytics-service.service';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, InterceptorComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NbTooltipModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    NgbTooltipModule,
    FormsModule,
    NbSelectModule,
    ReactiveFormsModule,
    ThemeModule.forRoot(),
    NbCardModule,
    NbRouteTabsetModule,
    NbLayoutModule,
    NbTabsetModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NgxAuthModule,
    NbCheckboxModule,
    NbSpinnerModule,
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'login',
          token: {
            class: NbAuthJWTToken,
            key: 'token', // this parameter tells where to look for the token
          },
          baseEndpoint: 'https://sbdev.mokacloud.com.br:8080',
          login: {
            endpoint: '/auth/loginWeb',
            method: 'post'
          },
        }),
      ],
      forms: {
        login: {
          redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
          strategy: 'login',  // strategy id key.
          rememberMe: true,   // whether to show or not the `rememberMe` checkbox
          showMessages: {     // show/not show success/error messages
            success: true,
            error: true,
          },
          logout: {
            redirectDelay: 500,
            strategy: 'login',
          },
          validation: {
            password: {
              required: true,
              minLength: 4,
              maxLength: 50,
            }
          }
        },
      },
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ToasterModule
  ],
  bootstrap: [AppComponent],
  providers: [
    EnvServiceProvider,
    AuthGuard,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorComponent, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    GoogleAnalyticsServiceService
  ],
})
export class AppModule {
}
