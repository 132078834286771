
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { EnvService } from '../env.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  baseEndpoint = this.env.apiUrlTest;

  authToken;
  httpOptions;
  httpOptionsForm;
  httpOptionsDownload;

  constructor(private httpClient: HttpClient,
    private env: EnvService,
    private authService: NbAuthService) {

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.authToken = token.getValue();
        this.setHeaders();
      }
    });
  }

  setHeaders() {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + this.authToken
      })
    }

    this.httpOptionsForm = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + this.authToken
      })
    }

    this.httpOptionsDownload = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + this.authToken
      }),
      responseType: 'blob'
    }
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let longMsg = ''
    let mSize = 21
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message.substring(0, mSize);
      longMsg = error.error.message
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = error.message.substring(0, mSize);
      longMsg = `Erro na Requisição. Código do erro: ${error.status}, ` + `mensagem: ${error.message}`
    }
    console.log(longMsg)
    return throwError(error.error);
  };

  async getMenus() {

    let url = '/api/mokaweb/getMenuList';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getUserMenus() {

    let url = '/api/mokaweb/getUserMenuList';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getRegisteredUsers() {

    let url = '/api/register/users';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getResources() {

    let url = '/api/powerbi/getResources';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeRegisteredUser(user) {

    let url = '/api/register/users/' + user.id;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async createNewUser(user, resourceList, placeList) {

    let url = '/api/register/users';

    return this.httpClient.post(this.baseEndpoint + url, { user: user, resourceList: resourceList, placeList: placeList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setRegisteredUser(user, resourceList, placeList) {

    let url = '/api/register/users/' + user.id;

    return this.httpClient.put(this.baseEndpoint + url, { user: user, resourceList: resourceList, placeList: placeList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeMenu(menuId) {

    let url = '/api/mokaweb/removeMenu/' + menuId;

    // let httpParams = new HttpParams().set('id', menuId);
    // this.httpOptions.append('params', httpParams);

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setMenu(menu) {

    let url = '/api/mokaweb/setMenu';

    return this.httpClient.post(this.baseEndpoint + url, { menu: menu }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateOrderMenu(menuList) {

    let url = '/api/mokaweb/updateOrderMenu';

    return this.httpClient.put(this.baseEndpoint + url, { menuList: menuList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeResource(resourceId) {

    let url = '/api/powerbi/removeResource/' + resourceId;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setResource(resource) {

    let url = '/api/powerbi/setResource';

    return this.httpClient.post(this.baseEndpoint + url, { resource: resource }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getResourceHeightList() {

    let url = '/api/powerbi/getResourceHeightList';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getPowerBiAcessToken() {

    let url = '/api/powerbi/auth';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setResourceHeight(resourceHeight) {

    let url = '/api/powerbi/setResourceHeight';

    return this.httpClient.post(this.baseEndpoint + url, { resourceHeight: resourceHeight }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeResourceHeight(resourceHeightId) {

    let url = '/api/powerbi/removeResourceHeight/' + resourceHeightId;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setCredentialData(credentialData) {

    let url = '/api/powerbi/setCredential';

    return this.httpClient.post(this.baseEndpoint + url, { credential: credentialData }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCredentialData() {

    let url = '/api/powerbi/getCredentials';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async refreshDataSet(datasetId) {

    let url = '/api/powerbi/refresh';

    return this.httpClient.post(this.baseEndpoint + url, { datasetId: datasetId }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getRefreshHistoryByQuantity(dataset_id, quantity) {

    let url = '/api/powerbi/refreshHistory';

    return this.httpClient.post(this.baseEndpoint + url, { dataset: dataset_id, quantity: quantity }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }


  async getPowerBiResource(menu) {

    let url = '/api/powerbi/getUserResources/';

    return this.httpClient.get(this.baseEndpoint + url + menu, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getVisual(visualName) {

    let url = '/api/helper/getVisual';

    return this.httpClient.post(this.baseEndpoint + url, { name: visualName }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getAllRefreshHistory(dataset_id) {

    let url = '/api/powerbi/refreshHistory';

    return this.httpClient.post(this.baseEndpoint + url, { dataset: dataset_id }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getDailyRefreshes(dataSetId) {

    let url = '/api/powerbi/getDailyRefreshes/';

    return this.httpClient.post(this.baseEndpoint + url + dataSetId, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getLoggedUser() {

    let url = '/api/logged/info';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCards(month) {

    let url;

    if (month) {
      url = '/api/cash/cards/list?month=' + month;
    } else {
      url = '/api/cash/cards';
    }

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  setInsightFeedback(insight, feedback) {

    let url = '/api/cash/insights/feedback';

    return this.httpClient.post(this.baseEndpoint + url, { insight: insight, feedback: feedback }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  getActiveInsights() {

    let url = '/api/cash/insights/actives'

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();

  }

  getInsightCategoryList() {

    let url = '/api/cash/insights/categories'

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setUserCorpAsActive(corpId) {

    let url = '/api/usercorp/active/';

    return this.httpClient.get(this.baseEndpoint + url + corpId, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }


  async getCashMonths(placeId) {

    let url;

    if (placeId) {
      url = '/api/cash/date/months?place_id=' + placeId;
    } else {
      url = '/api/cash/date/months';
    }

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getExtract(start, end, place, month) {

    let url;

    if (start && end) {
      url = '/api/cash/extract/' + place + '?start=' + start + '&end=' + end;
    } else {
      url = '/api/cash/extract/' + place + '?month=' + month;
    }

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getPlaces() {

    let url = '/api/cash/places';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCategories(place, month) {


    let url = '/api/cash/categories/' + place + '?month=' + month;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getFiles() {

    let url = '/api/cash/import/files';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getSpecificFile(fileId) {

    const options = {
      responseType: 'blob',
      headers: this.httpOptions
    };


    let url = `/api/cash/import/file/download/${fileId}`;
    return this.httpClient.get(this.baseEndpoint + url, this.httpOptionsDownload)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async sendFile(file, place, account) {
    let url = '/api/extract/import/file/'
    var formData: any = new FormData()
    formData.append("files", file)
    formData.append("place_id", place)
    formData.append("bank_id", account)
    return this.httpClient.post(this.baseEndpoint + url, formData, this.httpOptionsForm)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise()
  }

  async getFinalityList() {

    let url = '/api/cash/places/finalities';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getMarketList() {

    let url = '/api/cash/places/markets';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  // CRUD CONDOMINIUMS
  async createNewCondo(condo) { // CREATE
    let url = '/api/cash/places';

    return this.httpClient.post(this.baseEndpoint + url, { place: condo }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getRegisteredCondos() { // READ
    let url = '/api/cash/places/full';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setRegisteredCondo(condo) { // UPDATE
    let url = '/api/cash/places';

    return this.httpClient.put(this.baseEndpoint + url, { place: condo }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeRegisteredCondo(condo) { // DELETE
    // TODO
  }

  async getCashFlowMain(place_id, start, end, month?) {
    let url = '/api/cash/flow/' + place_id + '?start=' + start + '&end=' + end //+ '&month=' + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCashWeeklyFlow(place_id, start, end, month?) {
    let url = '/api/cash/weeklyflow/' + place_id + '?start=' + start + '&end=' + end //+ '&month=' + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCashDays(place_id) {
    let url = '/api/cash/date/days/' + place_id

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  // RECEIPTS PAGE
  async getReceiptsMain(place_id, month) {
    let url = "/api/cash/receive/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getReceiptsSecondary(place_id, month) {
    let url = "/api/cash/month/receive/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  // PAYMENTS PAGE
  async getPaymentsMain(place_id, month) {
    let url = "/api/cash/pay/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getPaymentsSecondary(place_id, month) {
    let url = "/api/cash/chart/categories/pay/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getDealerShipData(place_id, month) {
    let url = "/api/cash/manager/dealership/" + place_id + "?month=" + month;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getBookeeperData(place_id) {
    let url = "/api/cash/dealer/place/" + place_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateBookeeperData(place_id, bookeeperConfig) {
    let url = "/api/cash/dealer/place/" + place_id;

    return this.httpClient.put(this.baseEndpoint + url, {
      energy: bookeeperConfig.energy,
      gas: bookeeperConfig.gas,
      water: bookeeperConfig.water,
    }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  // TECNOSPEED
  async listTecnospeedData(place_id) {
    let url = "/api/cash/tecnospeed/data/" + "?place_id=" + place_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getTecnospeedData(account_id) {
    let url = "/api/cash/tecnospeed/data/" + account_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateTecnospeedData(place_id, tecnospeedConfig) {
    let url = "/api/cash/tecnospeed/data/" + place_id;
    let object = {
      "account": tecnospeedConfig
    };

    return this.httpClient.put(this.baseEndpoint + url, object, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  /* PAYMENTS IMPACT */
  async getImpactsByPlace(place_id: string) {
    let url = '/api/cash/impact/place/' + place_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getImpactsByInsight(insight_id: number) {
    let url = '/api/cash/impact/insight/' + insight_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async listPayableInsights(place_id: string) {
    let url = '/api/cash/insights/payables/' + place_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }
}
