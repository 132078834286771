<div class="header-container">
  <div class="logo-container">
    <a href="#" class="sidebar-toggle" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
      (click)="toggleSidebar()">
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <!-- <a class="logo" href="#" (click)="navigateHome()">Moka-<span>Web</span></a> -->
    <div *ngIf="hasCorp" class="corp-container">
      <img [src]="corp.image.path" class="corp-logo">
      <!-- <span class="corp-name">{{ corp.name }}</span> -->
    </div>
  </div>
  <!-- <nb-select status="primary" matRipple [selected]="currentTheme" (selectedChange)="changeTheme($event)">
    <nb-option *ngFor="let theme of themes" [value]="theme.value" matRipple>{{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item" icon="email-outline" matRipple [matRippleUnbounded]="true"
    [matRippleCentered]="true" style="padding: 0px !important;">
    </nb-action>
    
    <nb-action class="control-item" style='border-left: none !important;'>
      <div>
        <span style="color:white;">contact@cashvision.com</span>
      </div>
    </nb-action>

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"z></nb-search>
    </nb-action> -->

    <nb-action class="control-item" icon="bulb-outline" [badgeText]="insights.active" badgePosition="top right"
      badgeStatus="danger" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true" (click)="onInsightsClick()">
      <!-- <span *ngIf="insights.active > 0" class="insights-badge">
        {{ insights.active }}
      </span> -->
    </nb-action>

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true">
      <nb-user [nbContextMenu]="userMenu" [name]="user?.name" [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>

  <ngx-insights [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="large" nbSpinnerMessage="Carregando"
    class="insights-notifications" (closeInsights)="closeInsights()" (refresh)="getInsights()" [insights]="insights"
    [categoryList]="categoryList" *ngIf="showInsights"></ngx-insights>

</div>
